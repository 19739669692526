
let ajaxUpdate  = 200
let sName       = {gIncognito: 'incognito', gLp: 'lp', uFrameToken: 'auth_tokens', uFrameTheme: 'theme', uBoToken: 'bo_token', uBoLogin: 'bo_login'}
let os          = {extProp: {1: 'Windows®', 2: 'Android™', 3: 'macOS', 4: 'Linux', 5: 'iOS'}, lCase: ['windows','android','mac','linux','iphone'], uCase: ['Windows','Android','macOS','Linux','iOS'], sCase: ['win','and','mac','lin','ios']}
let apiLinkName = {
    productsLink: [
        'DISTRLINK',
        'WINLINK64', 'WINLINK32', 'WINLINK64_KSK', 'WINLINK64_KPM',
        'ANDLINK', 'ANDLINK_KPM', 'ANDLINK_KIS', 'ANDLINK_KPMA', 'ANDLINK_KWCA',
        'MACLINK', 'MACLINK_KPM', 'MACLINK_KIS', 'MACLINK_KPMW', 'MACLINK_KPMM',
        'LINLINK64', 'LINLINK32',
        'IOSLINK', 'IOSLINK_KPM', 'IOSLINK_KPMI', 'IOSLINK_KWCI',
        'LINK',
    ],
    products: [
        'AdGuard',
        'Dr.Web Мобайл',
        'Dr.Web Премиум',
        'Dr.Web Стандарт',
        'ESET NOD32 Parental Control',
        'ESET NOD32 Internet Security',
        'itHelper',
        'itHelper Optimiser',
        'itHelper Optimiser расширенный',
        'itHelper Базовая помощь с 1 консультацией',
        'itHelper Расширенная помощь с неограниченным кол-вом консультаций',
        'itHelper Optimizer + GPT',
        'itHelper Optimizer + GPT ТМ',

        'Kaspersky Anti-Virus',
        'Kaspersky Cloud Password Manager',
        'Kaspersky Cloud Password Manager for macOS',
        'Kaspersky CRYSTAL',
        'Kaspersky for Android',
        'Kaspersky for iOS',
        'Kaspersky for macOS',
        'Kaspersky for Windows',
        'Kaspersky Internet Security',
        'Kaspersky Internet Security для Android',
        'Kaspersky Internet Security for Android + Kaspersky WhoCalls',
        'Kaspersky Internet Security для macOS',
        'Kaspersky Password Manager',
        'Kaspersky Password Manager for Android',
        'Kaspersky Password Manager for iOS',
        'Kaspersky Password Manager для macOS',
        'Kaspersky Password Manager for Windows',
        'Kaspersky Password Manager для Android',
        'Kaspersky Password Manager для iOS',
        'Kaspersky Password Manager для Windows',
        'Kaspersky Plus',
        'Kaspersky Plus + Who Calls',
        'Kaspersky Plus + Who Calls + Safe Kids',
        'Kaspersky Premium + Who Calls',
        'Kaspersky Safe Kids',
        'Kaspersky Safe Kids для Android',
        'Kaspersky Safe Kids для iOS',
        'Kaspersky Safe Kids для macOS',
        'Kaspersky Safe Kids для Windows',
        'Kaspersky Secure Connection',
        'Kaspersky Security',
        'Kaspersky Security Cloud Adaptivity',
        'Kaspersky Standard',
        'Kaspersky Standard Mobile',
        'Kaspersky Total Security',
        'Kaspersky Total Security для Windows',
        'Kaspersky Who Calls',
        'Kaspersky WhoCalls for Android',
        'Kaspersky WhoCalls for iOS',

        'MyItMaster',
        'MyITMaster Компьютерная помощь',
        'PRO32 Mobile Security',
        'PRO32 Total Security',
        'PRO32 Ultimate Security',

        'Дистрибутив Kaspersky Internet Security для Android',
        'Дистрибутив Kaspersky Password Manager для Android',
        'Дистрибутив Kaspersky Password Manager для iOS',
        'Дистрибутив Kaspersky Password Manager для Windows',
        'Дистрибутив Kaspersky Safe Kids для Android',
        'Дистрибутив Kaspersky Safe Kids для iOS',
        'Дистрибутив Kaspersky Safe Kids для Windows',
        'Дистрибутив Kaspersky Total Security для Windows',

        'Дистрибутив для Android',
        'Дистрибутив для iOS',
        'Дистрибутив для Linux',
        'Дистрибутив для macOS',
        'Дистрибутив для Windows',
        'Дистрибутив для всех устройств',
        'Дистрибутив для всех платформ',
        'для Android',
        'для iOS',
        'для Linux (32 bit)',
        'для Linux',
        'для macOS',
        'для Windows',
        'для Windows (32 bit)',
        'для всех устройств',

        'Цифровая помощь',
    ],
    productsForOs: [
        'для Linux','для Windows','для Android','для iOS','для Mac','для всех',
        'for Linux','for Windows','for Android','for iOS','for Mac','for all',
    ],
}
let fStaticUrl  = ['/shopwindow/wifire/','/shopwindow/wifire','/static/','/static','/']
let moneyMark   = '₽'

let intLp = {
    iLpKey: {
        ru: {keyAction: {activate: 'Подключить', suspend: 'Отключить', resume: 'Возобновить', confirm: 'Подтверждаю', control: 'Управление'}},
        en: {keyAction: {activate: 'Activate', suspend: 'Suspend', resume: 'Resume', confirm: 'Confirm', control: 'Control'}},
    },
    iLpPeriod: {
        ru: {productPeriod: {1: 'день', 7: 'нед.', 30: 'мес', 60: '2 мес', 90: '3 мес', 365: 'год'}},
        en: {productPeriod: {1: 'day', 7: 'week', 30: 'mo', 60: '2 mo', 90: '3 mo', 365: 'year'}},
    },
    iLpTrialDay : {
        ru: {
            dayFree: {7: 'Неделя', 14: '14 дней', 30: 'Месяц', 60: '2 Месяца', 90: '3 Месяца'},
            dayFreeL: {7: 'Неделю бесплатно', 14: '14 дней бесплатно', 30: '30 дней бесплатно', 60: '60 дней бесплатно', 90: '90 дней бесплатно'},
            afterFree: {7: 'с 8-го', 14: 'с 15-го', 30: 'со 2-го', 60: 'c 3-го', 90: 'c 4-го'},
            afterFreeL: {7: 'с 8-го дня', 14: 'с 15-го дня', 30: 'со второго месяца', 60: 'с третьего месяца', 90: 'с четвёртого месяца'},
        },
        en: {
            dayFree: {7: '7 days', 14: '14 days', 30: 'month', 60: '2 month', 90: '3 month'},
            dayFreeL: {7: '7 days free', 14: '14 days free', 30: '30 days free', 60: '60 days free', 90: '90 days free'},
            afterFree: {7: 'from 8', 14: 'from 15', 30: 'from 2nd', 60: 'from the 3rd', 90: 'from the 4rd'},
            afterFreeL: {7: 'from 8 day', 14: 'from 15 day', 30: 'from 2nd month', 60: 'from the 3rd month', 90: 'from the 4rd month'},
        },
    },
    iLpOperation: {
        ru: {
            pageUpdate: {
                errorInternal: 'Время сессии истекло, попробуйте авторизоваться заново',
                successInternal: 'Для полноценной защиты ваших устройств скачайте и установите программу антивируса, которую вы найдёте в разделе «Мои подписки».\n\n* Программа входит в стоимость подписки.'
            }
        },
        en: {
            pageUpdate: {
                errorInternal: 'Session timed out, please try to log in again',
                successInternal: 'For full protection of your devices, download and install the antivirus program that you will find in the "My Subscriptions" section.\n\n*The program is included in the subscription price.'
            },
        },
    },
    iLpUpload: {
        ru: {
            outMessage: {err: 'Ошибка загрузки файла', pro: 'В процессе ...', god: 'Файл успешно загружен'},
            formFileLoad: {firstLoad: {title: 'Загрузите файл xls', button: 'Загрузить'}},
        },
        tr: {
            outMessage: {err: 'File upload error', pro: 'Processing ...', god: 'Dosya başarılı şekilde yüklendi. İşleme sonuçları için lütfen e-postanızı kontrol edin.'},
            formFileLoad: {
                firstLoad: {title: 'Yeni üyelik için XLS dosyasını yükleyin', button: 'Yeni üyelikleri yükleyin'},
                noFirstLoad: {title: 'İptal edilmiş üyelik için XLS dosyasını yükleyin', button: 'İptal edilmiş üyelikleri yükleyin'},
            }
        }
    },
}

let eIcon = {
    delI : [101,102,103,104,109,110,111,112,113,115,119,124,125,127,155],
    noShowI : [106,161,164,165,167,168,169,171,174,176,187,193],//106,187,192//161,191
    osI : {
        1 : {icon: "icon-icons-01"},
        2 : {icon: "icon-icons-02"},
        3 : {icon: "icon-icons-03"},
        4 : {icon: "icon-icons-04"},
        5 : {icon: "icon-icons-03"},
    },
    propI: {
        105 : {icon: "icon-icons-06", ru: 'Оптимален для активных интернет-пользователей', en: 'Ideal for active Internet users'},
        107 : {icon: "icon-icons-05", ru: 'Функция "Родительский контроль"', en: 'Parental control function'},
        108 : {icon: "icon-icons-12", ru: 'Шифрование файлов и управление паролями', en: 'File encryption and password management'},
        114 : {icon: "icon-icons-06", ru: 'Быстрый проактивный антивирус', en: 'Fast proactive antivirus'},
        116 : {icon: "icon-icons-14", ru: 'Защищает от интернет-угроз', en: 'Protects against Internet threats'},
        117 : {icon: "icon-icons-15", ru: 'Помогает контролировать время использования устройства', en: 'Helps control device usage time'},
        118 : {icon: "icon-icons-16", ru: 'Позволяет узнать местонахождение устройства', en: 'Lets you know the location of the device'},
        120 : {icon: "icon-icons-18", ru: 'Анти-баннер', en: 'Anti-banner'},

        121 : {icon: "icon-icons-15", ru: 'Контроль приложений и времени', en: 'Application and time control'},
        122 : {icon: "icon-icons-16", ru: 'Определение местоположения', en: 'Location determination'},
        123 : {icon: "icon-icons-17", ru: 'Сообщение ребенку', en: 'Message to a child'},
        128 : {icon: "icon-icons-28", ru: 'Обнаруживает во входящих звонках неизвестные номера (интернет-соединение не требуется)', en: 'Detects unknown numbers in incoming calls (no internet connection required)'},
        129 : {icon: "icon-icons-29", ru: 'Предоставляет подробные сведения о неизвестных номерах', en: 'Provides detailed information about unknown numbers'},
        130 : {icon: "icon-icons-30", ru: 'Блокирует нежелательные звонки и сообщает информацию о них', en: 'Blocks unwanted calls and reports information about them'},
        132 : {icon: "icon-icons-32", ru: 'Непрерывно повышает точность обнаружения спама за счет обновления антиспам-баз', en: 'Continuously improves spam detection accuracy by updating anti-spam databases'},
        133 : {icon: "icon-icons-33", ru: 'Работы выполняются удаленно', en: 'Work is done remotely'},
        138 : {icon: "icon-icons-38", ru: 'Облачная защита', en: 'Cloud protection'},
        139 : {icon: "icon-icons-39", ru: 'Технология безопасного соединения (Kaspersky Secure Connection)', en: 'Secure connection technology (Kaspersky Secure Connection)'},
        140 : {icon: "icon-icons-40", ru: 'Резервное копирование', en: 'Backup'},

        141 : {icon: "icon-icons-41", ru: 'Менеджер паролей', en: 'Password manager'},
        142 : {icon: "icon-icons-42", ru: 'Программа "Safe Kids"', en: 'Program "Safe Kids"'},
        143 : {icon: "icon-icons-43", ru: 'Защита конфиденциальности', en: 'Privacy Protection'},
        151 : {icon: "icon-icons-51", ru: 'Контроль состояния ПК', en: 'PC Health Monitoring'},
        153 : {icon: "icon-icons-53", ru: 'Очистка системы', en: 'Cleaning the system'},
        154 : {icon: "icon-icons-54", ru: 'Чат с технической поддержкой онлайн', en: 'Chat with technical support online'},
        157 : {icon: "icon-icons-57", ru: 'Разовое обращение', en: 'One-time treatment'},

        162 : {icon: "icon-icons-26", ru: 'Интернет-защита', en: 'Internet protection'},
        163 : {icon: "icon-icons-49", ru: 'Восстановление зараженного компьютера', en: 'Recovery of an infected computer'},
        165 : {icon: "icon-icons-39", ru: 'Защита от шифровальщиков', en: 'Ransomware Protection'},
        166 : {icon: "icon-icons-24", ru: 'Сетевой экран и Защита от сетевых атак', en: 'Firewall and Network Attack Blocker'},
        170 : {icon: "icon-icons-48", ru: 'Управление приложениями', en: 'Application management'},
        172 : {icon: "icon-icons-23", ru: 'Контроль камеры и микрофона', en: 'Camera and microphone control'},
        173 : {icon: "icon-icons-29", ru: 'Поиск небезопасных настроек', en: 'Finding insecure settings'},
        175 : {icon: "icon-icons-47", ru: 'Поиск утечки данных', en: 'Finding data leaks'},//195
        177 : {icon: "icon-icons-16", ru: 'Поиск устройства', en: 'Device search'},
        178 : {icon: "icon-icons-46", ru: 'Защита от криптоугроз', en: 'Protection against crypto threats'},
        179 : {icon: "icon-icons-50", ru: 'Премиум-версия Kaspersky Password Manager', en: 'Premium version of Kaspersky Password Manager'},
        180 : {icon: "icon-icons-21", ru: 'Защита от звонков спамеров и мошенников', en: 'Protection against calls from spammers and scammers'},

        182 : {icon: "icon-icons-51", ru: 'Проверка безопасности паролей', en: 'Password security check'},
        183 : {icon: "icon-icons-29", ru: 'Хранилище документов', en: 'Document storage'},
        184 : {icon: "icon-icons-20", ru: 'Обнаружение удаленного доступа', en: 'Remote access discovery'},
        186 : {icon: "icon-icons-14", ru: 'Приоритетная линия техподдержки', en: 'Priority support line'},
        191 : {icon: "icon-icons-15", ru: 'Антивирусная защита в режиме реального времени', en: 'Real-time antivirus protection'},
        192 : {icon: "icon-icons-13", ru: 'Защита платежей', en: 'Secure payments'},
        194 : {icon: "icon-icons-19", ru: 'Определитель номера Who Calls', en: 'Caller ID Who Calls'},
        195 : {icon: "icon-icons-19", ru: 'Поиск утечек данных', en: 'Finding data leaks'},
        196 : {icon: "icon-icons-06", ru: 'Оптимизация работы устройств', en: 'Optimizing device performance'},
        197 : {icon: "icon-icons-12", ru: 'Сохранение конфиденциальности пользователя', en: 'Preserving user privacy'},//del 143!
        198 : {icon: "icon-icons-26", ru: 'Защита от DDoS-атак', en: 'Protection against DDoS attacks'},
        199 : {icon: "icon-icons-46", ru: 'Защита от кражи персональных данных при подключении к публичным сетям Wi-Fi', en: 'Protection against identity theft when connecting to public Wi-Fi networks'},

        201 : {icon: "icon-icons-14", ru: 'Все возможности ItHelper', en: 'All features of ItHelper'},
        202 : {icon: "icon-icons-14", ru: '1 консультация инженера (установка/удаление программ, помощь в создании аккаунтов, помощь пожилым при работе с ПК и Интернетом)', en: '1 engineering consultation (installation/uninstallation of programs, assistance in creating accounts, assistance to the elderly in working with PCs and the Internet)'},
        203 : {icon: "icon-icons-14", ru: 'Неограниченное количество консультаций инженера (установка/удаление программ, помощь в создании аккаунтов, помощь пожилым при работе с ПК и Интернетом)', en: 'Unlimited number of engineer consultations (installation/uninstallation of programs, assistance in creating accounts, assistance to the elderly in working with PCs and the Internet)'},
        204 : {icon: "icon-icons-14", ru: 'Консультация по телефону, почте или в чате программы ItHelper', en: 'Consultation by phone, mail or chat in the ItHelper program'},
        205 : {icon: "icon-icons-14", ru: 'Круглосуточная поддержка 24х7', en: '24x7 support'},
        206 : {icon: "icon-icons-14", ru: 'Запись диалога, переписки и экрана при подключении', en: 'Recording of dialog, correspondence and screen when connected'},

        210 : {icon: "icon-icons-53", ru: "Очистка системы от мусорных файлов и ненужных программ", en: "Cleaning the system from junk files and unnecessary programs"},
        211 : {icon: "icon-icons-49", ru: "Исправление системных ошибок", en: "Fixing system errors"},
        212 : {icon: "icon-icons-17", ru: "Надежный и вежливый собеседник", en: "Reliable and polite interlocutor"},
        213 : {icon: "icon-icons-62", ru: "Написание текстов, писем, резюме, постов и пр.", en: "Writing texts, letters, resumes, posts, etc."},
        214 : {icon: "icon-icons-61", ru: "Решение задач, помощь в обучении и объяснении", en: "Solving problems, helping with training and explanation"},
        215 : {icon: "icon-icons-60", ru: "Генерация идей для любых целей (для проектов, праздников, подарков, поздравлений)", en: "Generating ideas for any purpose (for projects, holidays, gifts, congratulations)"},
        216 : {icon: "icon-icons-26", ru: "Перевод текстов, в т.ч. на искусственные языки (эльфийский и клингонский)", en: "Translating texts, including into artificial languages (Elven and Klingon)"},
        217 : {icon: "icon-icons-57", ru: "Написание кода, код-ревью и рефакторинг", en: "Writing code, code review and refactoring"},

    },
}

let allParams = {
    allMatch : {
        'email' : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    allMasks : {
        'empty' : '',
        'digit' : /\D/g,
        'guid'  : /[^A-Za-z0-9=?/]+/g,
        'name'  : /[^A-Za-zА-Яа-я]+/g,
        'email' : /[^A-Za-z0-9=\-@_.]+/g,
        'url'   : /[^A-Za-z0-9=_:@/.&-?]+/g,
        'xhrBl' : 'bang',
        'xhrBc' : 'platform-api',
        'xhrFr' : 'frontend',
        'xhrDm' : 'demo',
        'phone' : {
            ru: {startPlace: '+7', nums: 11, reverseSplit: [{primary: 9, glue: '-'}, {primary: 7, glue: '-'}, {primary: 4, glue: ') '}, {primary: 1, glue: ' ('}]},
            kz: {startPlace: '+996', nums: 12, reverseSplit: [{primary: 9, glue: ' '}, {primary: 6, glue: ' '}, {primary: 3, glue: ' '}]},
        },
    },
    allDistributorOptions : {
        noPeriodAndLicense : [356],
    },
}

let searchStrMatchInArray = (field, array) => {
    let outVal = false
    array.map(item => {
        if (item === field) {
            outVal = true
        }
    })
    return outVal
}

let tokenIncorrect = (token = '') => {return (token + '' === '' || token + '' === 'null' || token + '' === 'error' || token + '' === 'undefined')}

let pMode = () => {
    let outVal = false
    try {sSet(sName.gIncognito, ajaxUpdate);sGet(sName.gIncognito)} catch (e) {outVal = true}
    return outVal
}

let osShowT = (clientOs = 0, mode = '') => {
    let caseVal = os.extProp
    if (mode !== '' && !!os[mode]) {caseVal = os[mode]}
    clientOs++
    return caseVal[clientOs] ?? ''
}

let osShow = (mode = '', hard = false) => {
    let outVal = -1
    let osVal = (navigator.appVersion).toLocaleLowerCase()
    let caseVal = os.lCase
    if (mode !== '' && !!os[mode]) {caseVal = os[mode]}
    if (osVal.indexOf(caseVal[3]) > -1) {outVal = 3}
    else if (osVal.indexOf(caseVal[1]) > -1) {outVal = 1}
    else if (osVal.indexOf(caseVal[4]) > -1) {outVal = 4}
    else if (osVal.indexOf(caseVal[3]) > -1) {outVal = 2}
    else if (!hard || (hard && osVal.indexOf(caseVal[0]) > -1)) {outVal = 0}
    return outVal
}

let intMode = (param) => {
    let outVal = false
    try {window.localStorage.getItem(param)}catch{outVal=true}
    return outVal
}

let vUrl = (string) => {
    let outVal=''
    try{outVal=(new URL(string)).href}catch(e){}
    return outVal
}

let oUrl = (type = 'address') => {
    let outVal = document.location.origin
    if (type + '' === 'gets') {outVal = decodeURIComponent(document.location.search)}
    return outVal
}

let uLocal = () => {return (window.location.hostname === 'localhost')}

// (window, localStorage)
let sSet = (param = '', value = '', jsonToString = false) => {
    try {
        param = param + ''
        value = value + ''
        if (jsonToString) {value=JSON.stringify(value)}
        return window.localStorage.setItem(param, value)
    } catch(e) {
    }
}
let sGet = (param = '', stringToJson = false) => {
    let outVal = window.localStorage.getItem(param) ?? ''
    if (stringToJson) {
        try {
            outVal = JSON.parse(outVal) ?? {}
            if (typeof(outVal) !== 'object') {
                outVal = {}
            }
        } catch(e) {
            outVal = {}
        }
    }
    return outVal
}
let slGet = (param, arr, out = '') => {
    let outVal = sGet(param)
    if (arr.indexOf(outVal) === -1) {
        outVal = out
    }
    return outVal
}
let sDel = (param = '', full = false) => {return !full ? window.localStorage.removeItem(param) : localStorage.clear()}
// (window, localStorage)

// (window, cookie)
let cSet = (param, value, time = 900) => {if(value + "" !== ""){document.cookie = param + "=" + value + ";max-age=" + time}}
let cGet = (param) => {
    let outVal = "";
    (document.cookie).split(';').forEach(item => {if (item.split('=')[0] + "" === param + "" && !!item.split('=')[1]) {outVal = item.split('=')[1];}})
    return outVal
}
let cDel = (param) => {document.cookie = param + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";}
// (window, cookie)

let ePrOs = (props = []) => {
    let outVal = []
    props.forEach(item => {
        if (parseInt(item) <= 5 && (parseInt(item) !== 5 || (parseInt(item) === 5 && props.indexOf('3') === -1))) {
            outVal.push(item)
        }
    })
    return outVal
}

let periodAndLic = (distributor) => {return allParams.allDistributorOptions.noPeriodAndLicense.indexOf(distributor) === -1}

let eCheck = (param = '', match = 'email') => {return (param + '').match(allParams.allMatch[match])}

let stringReplaceMask = (param = '', mask = 'digit', out = 'empty') => {
    let afterReplaceMask = false
    if (mask === 'xhr') {mask = 'xhrFr';out = 'xhrBc'}
    if (mask === 'xhrB') {mask = 'xhrFr';out = 'empty';afterReplaceMask = true}
    let outVal = (param + '').replace(allParams.allMasks[mask], allParams.allMasks[out]).split('..').join('.')
    if (afterReplaceMask) {
        outVal = outVal.split('.')
        if(outVal[1]===allParams.allMasks['xhrDm']){outVal[0] = '' + outVal[0].split('//')[0] + '//' + allParams.allMasks['xhrBl']}
        outVal = outVal.join('.')
    }
    return outVal
}

let phoneDraw = (phone = '', country = 'ru') => {
    (allParams.allMasks.phone[country].reverseSplit).map((item, i) => {
        if (i === 0) {phone = phone.substr(0, allParams.allMasks.phone[country].nums)}
        if (phone.length > item.primary) {phone = phone.substr(0, item.primary) + item.glue + phone.substr(item.primary, phone.length - item.primary)}
    })
    return '+' + phone
}

let pRound = (price) => {return price.split('.00')[0]}

let urlGuidSearch = (pos = '', separator = ['/']) => {
    let outVal = ''
    separator.forEach(item => {
        if (((document.location.pathname).split(item)[pos] ?? '') !== '' && outVal === '') {
            outVal = (document.location.pathname).split(item)[pos]
        }
    })
    return outVal
}

let uSearch = (param = '') => {
    let outVal = ''
    let stringUrl = decodeURIComponent(document.location.search).replace(allParams.allMasks.url, '')
    if (stringUrl.substr(0, 1) === '?') {
        stringUrl = stringUrl.slice(1).split('&')
        stringUrl.forEach((element) => {
            if (element.split('=')[0] === param && !!element.split('=')[1]) {
                outVal = element.split('=')[1] + ''
            }
        })
    }
    return outVal
}

let pageHeight = (heightPlus = 0) => {return document.body.clientHeight + heightPlus}

let postMessageSend = (message = '', target = '*') => {window.parent.postMessage(message, target)}

let showOsTextInExtProps = (extProps = []) => {
    let outVal = []
    Object.keys(os.extProp).forEach(item => {if (extProps.indexOf(item + '') > -1) {outVal.push(os.extProp[item])}})
    return outVal.join(', ')
}

let showOsIfIsset = (extProps) => {
    let outVal = false
    extProps.forEach(item => {if (parseInt(item) < 5) {outVal = true}})
    return outVal
}

let isOs = (extProps) => {
    let out = false
    extProps.forEach(item => {if (item <= 5) {out = true}})
    return out
}

let ePrLimit = 330
let showOsNoBlock = (extProp) => {return (parseInt(extProp) > 100 && parseInt(extProp) < ePrLimit)}
let ePrNoOs = (prop = 0, ex = false) => {prop = parseInt(prop);return (prop>100 && prop<ePrLimit && !!eIcon.propI[prop] && (!ex || (ex && prop!==105)))}

let stringDeleteYear = (param) => {
    if (parseInt(param.substr(param.length - 4, 4)) > 2000 && parseInt(param.substr(param.length - 4, 4)) < 2100) {
        param = param.substr(0, param.length - 4)
    }
    return param
}

let stringOsNameTrim = (param) => {return (param + '').toLocaleLowerCase().replace('64', '').replace('32', '').replace('link', '')}

let duration = (lp = 'ru', period = 30, slash = false) => {
    period = parseInt(period)
    return (slash ? '/' : '') + iLp.iLpPeriod[lp].productPeriod[period]
}

let if500 = (param = '') => {return (param.toString().substr(0,1) === '5')}

let partnerHelpLink = (url = '') => {
    let redirectUrl = ''
    if ((['expired_index','kav', 'kis', 'kids', 'kisa', 'kis_mobile']).indexOf(url) > -1) {
        redirectUrl = "https://support.kaspersky.com/help/"
    } else if ((['drweb']).indexOf(url) > -1) {
        redirectUrl = "https://download.drweb.ru/doc/"
    } else if ((['nod32', 'eset_mobile']).indexOf(url) > -1) {
        redirectUrl = "https://help.eset.com/?lang=ru-RU"
    }
    return redirectUrl
}

let timeShift = (ms) => {return new Promise(resolve => setTimeout(resolve, ms))}

let productNameConvertToShowPage = (name = '') => {
    let nameAfter = [' Безопасный',' годовой',' (',' - 20',' 20',' на ',' для ',' 5 устр',' 3 устр',' 2 устр',' 1 устр', ' 3  ', ' 1 lic', ' 3 lic', ' 5 lic', ' скидка', ' 1 ПК']
    let nameBefore = ['Программа мотивации - ']
    nameAfter.map(item => {
        name = name.split(item)[0]
    })
    nameBefore.map(item => {
        if (name.split(item).length > 1) {
            name = name.split(item)[1]
        }
    })
    return name
}

let dateCheck = (date = '') => {
    let outVal = false
    try {
        if (date !== '') {
            if (date.split(' ').length === 2) {
                const [dateDate, dateTime] = date.split(' ')
                const [dateDateY, dateDateM, dateDateD] = dateDate.split('-')
                const [dateTimeH, dateTimeM, dateTimeS] = dateTime.split('-')
                if (parseInt(dateDateY) < 2000 || parseInt(dateDateY) > 2100) {
                } else if (parseInt(dateDateM) > 12) {
                } else if (parseInt(dateDateD) > 31) {
                } else if (parseInt(dateTimeH) > 24) {
                } else if (parseInt(dateTimeM) > 60) {
                } else if (parseInt(dateTimeS) > 60) {
                } else {
                    outVal = true
                }
            } else {
                const [dateShortD, dateShortM, dateShortY] = date.split('.')
                if (parseInt(dateShortY) < 2000 || parseInt(dateShortY) > 2100) {
                } else if (parseInt(dateShortM) > 12) {
                } else if (parseInt(dateShortD) > 31) {
                } else {
                    outVal = true
                }
            }
        }
    } catch (_) {
    }
    return outVal
}

let textDuration = (period = 30, slash = false) => {
    period = parseInt(period)
    if (period === 1) {period = 'день'} else if (period === 30) {period = 'месяц'} else {period = 'год'}
    return (slash ? '/' : '') + period
}

let endModeWord = (word, number, values) => {
    if (number === 1) {word += values[0]} else if (number >= 5) {word += values[2]} else {word += values[1]}
    return word
}

let productNameClearAndAddDevice = (name, devices, devicesHide = false) => {
    let nameTemp = name.split(' (')
    if (nameTemp[1]) {nameTemp[1] = nameTemp[1].split(')')[1]}
    nameTemp = nameTemp.join(' ')
    nameTemp = nameTemp.split(' 20')[0]
    if (!devicesHide) {
        nameTemp = nameTemp + " " + "(" + devices + "&nbsp;" + endModeWord('устройств', devices, ['о', 'а', '']) + ")"
    }
    return nameTemp
}

let textHtmlClear = (textHtml) => {
    if (textHtml !== null) {
        textHtml = textHtml.split('<b>').join('').split('</b>').join('')
        textHtml = textHtml.split('<p>').join('').split('</p>').join('')
        textHtml = textHtml.split('<li>').join(' - ').split('</li>').join(' <br />')
        textHtml = textHtml.split('<ul>').join(' <br />').split('<ul >').join(' <br />').split('</ul>').join(' ')
    }
    return textHtml
}

let changeThemeHit = (product) => {
    let outVal = ''
    if (product.distributor === 17) {
        outVal = 'KL'
        if ((product.name).indexOf('Kaspersky Safe Kids') > -1 || (product.name).indexOf('Kaspersky Password Manager') > -1) {
            outVal = 'KL_GreenLight'
        } else if ((product.name).indexOf('Kaspersky Standard') > -1) {
            outVal = 'KL_Standard'
        } else if ((product.name).indexOf('Kaspersky Plus') > -1) {
            outVal = 'KL_Plus'
        } else if ((product.name).indexOf('Kaspersky Premium') > -1) {
            outVal = 'KL_Premium'
        }
    } else if (product.distributor === 16) {
        outVal = 'DW'
    } else if (product.distributor === 367) {
        outVal = 'ITH'
    }
    return outVal
}

export {
    tokenIncorrect, osShow, intMode, vUrl, intLp, oUrl, uLocal, sSet, sGet, slGet, sDel, ePrOs, periodAndLic, eCheck,
    stringReplaceMask, searchStrMatchInArray, showOsTextInExtProps, timeShift, cSet, cGet, cDel, partnerHelpLink,
    if500, ajaxUpdate, pMode, ePrNoOs, osShowT, phoneDraw, urlGuidSearch, uSearch, pageHeight, postMessageSend,
    showOsIfIsset, showOsNoBlock, stringDeleteYear, stringOsNameTrim, duration, pRound, isOs, sName, apiLinkName,
    productNameConvertToShowPage, dateCheck, eIcon, textDuration, endModeWord, productNameClearAndAddDevice,
    textHtmlClear, changeThemeHit, fStaticUrl,
}
